import React from "react";
import Layout from "../components/layout";
// import Image from '../components/image'
import SEO from "../components/seo";
import Navigation from "../components/navigation";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Product from "../components/product";
import ReactGA from "react-ga";
import Hero from "../components/hero";
import DonationFooter from "../components/donation-footer";

class CodeSnippets extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			data: []
		};
	}

	componentDidMount() {
		ReactGA.initialize("UA-115464417-1");
		if (!(window.location.hostname === "localhost")) {
			ReactGA.pageview(window.location.pathname + window.location.search);
		}

		// const contentful = require("contentful");
		// const client = contentful.createClient({
		// 	space: "1s4yepg5zo3l",
		// 	accessToken:
		// 		"4a805250df6a975be5f2741cc32d6f78a852685a66b06cbe70c23aece58cba04"
		// });
		// client
		// 	.getEntries({
		// 		content_type: "codeSnippets"
		// 		// "fields.slug": "depth-first-search-code"
		// 	})
		// 	.then(response => {
		// 		this.setState({ data: response.items }, () => {
		// 			console.log(this.state.data);
		// 		});
		// 	})
		// 	.catch(console.error);
	}

	render() {
		return (
			<Layout>
				<SEO title="Code Snippets" keywords={[`code snippets`]} />
				<Navigation />

				<Hero
					header="Code Snippets"
					subHeader="Whenever I write programs, there are some lines of code that you just don't want to memorize. I have created snippets that I store here."
					maxWidth={650}
				/>

				<Container
					style={{ padding: 20, display: "flex", justifyContent: "center" }}
				>
					<Col
						style={{
							maxWidth: 500
						}}
					>
						<Row>
							<Product
								name="Generate Subsets Using Recursion"
								decription="This code in written in C++"
								link="/code-snippets/generate-subsets-using-recursion-in-cpp"
							/>
						</Row>
						<Row>
							<Product
								name="Dynamically Allocate Matrix in C"
								decription="This code in written in C++"
								link="/code-snippets/dynamically-allocate-matrix-in-c"
							/>
						</Row>
						<Row>
							<Product
								name="Async JS Import"
								decription="Import Asynchronous (async) JavaScript (js) Script in Reactjs"
								link="/code-snippets/import-asynchronous-async-javascript-js-script-in-reactjs"
							/>
						</Row>
						<Row>
							<Product
								name="Doubly Linked List | Code Tutorial"
								decription="Code Tutorial for the Doubly Linked List"
								link="/code-snippets/doubly-linked-list-code"
							/>
						</Row>
						<Row>
							<Product
								name="Permutations Of String | Code Tutorial"
								decription="A no-bs line-by-line code explanation of permutating characters in a string."
								link="/code-snippets/permutations-of-string"
							/>
						</Row>
						<Row>
							<Product
								name="Breadth First Search | Code Tutorial"
								decription="A no-bs line-by-line code explanation of the Breadth First Search Algorithm."
								link="/code-snippets/breadth-first-search-code"
							/>
						</Row>
						<Row>
							<Product
								name="Sudoku Solving Algorithm with Backtracking | Code Tutorial"
								decription="A no-bs line-by-line code explanation of an algorithm to solve the Sudoku puzzle."
								link="/code-snippets/sudoku-code"
							/>
						</Row>
						<Row>
							<Product
								name="spacer"
								decription="spacer"
								link="/code-snippets/spacer-code"
							/>
						</Row>
						<Row>
							<Product
								name="Dijkstra's Algorithm | Code Tutorial"
								decription="A no-bs line-by-line code explanation of the Dijkstra's Algorithm."
								link="/code-snippets/dijkstras-algorithm-code"
							/>
						</Row>
						<Row>
							<Product
								name="Tower Of Hanoi | Code Tutorial"
								decription="A no-bs line-by-line code tutorial of the Tower of Hanoi Problem."
								link="/code-snippets/tower-of-hanoi-code"
							/>
						</Row>
						<Row>
							<Product
								name="Circular Linked List | Code Tutorial"
								decription="Code for Circular Lined List"
								link="/code-snippets/circular-linked-list-code"
							/>
						</Row>
						<Row>
							<Product
								name="Depth First Search | Code Tutorial"
								decription="A no-bs line-by-line code explanation of the legendary depth-first search."
								link="/code-snippets/depth-first-search-code"
							/>
						</Row>
						<Row>
							<Product
								name="Prim's Algorithm | Code Tutorial"
								decription="A no-bs line-by-line code explanation of the Prim's Algorithm."
								link="/code-snippets/prims-algorithm-code"
							/>
						</Row>
						<Row>
							<Product
								name="Merge Sort With Linked List | Code Tutorial"
								decription="A no-bs line-by-line code explanation of implementing the Mergesort with a Linked List in pure C."
								link="/code-snippets/merge-sort-with-linked-list-code"
							/>
						</Row>
						<Row>
							<Product
								name="Doubly Circular Linked List | Code Tutorial"
								decription="A no-bs line-by-line code explanation of the legendary Doubly Circular Linked List."
								link="/code-snippets/doubly-circular-linked-list-code"
							/>
						</Row>
						<Row>
							<Product
								name="N-Queens Problem | Code Tutorial"
								description="The n-queens puzzle is the problem of placing eight chess queens on an 8×8 chessboard so that no two queens threaten each other; thus, a solution requires that no two queens share the same row, column, or diagonal."
								link="/code-snippets/n-queen-problem-code"
							/>
						</Row>
						<Row>
							<Product
								name="Threaded Binary Tree | Code Tutorial"
								description="A binary tree is threaded by making all right child pointers that would normally be null point to the inorder successor of the node (if it exists), and all left child pointers that would normally be null point to the inorder predecessor of the node."
								link="/code-snippets/threaded-binary-tree-code"
							/>
						</Row>
						<Row>
							<Product
								name="Max And Min Using Divide And Conquer | Code Tutorial"
								description="In computer science, divide and conquer is an algorithm design paradigm based on multi-branched recursion. A divide-and-conquer algorithm works by recursively breaking down a problem into two or more sub-problems of the same or related type, until these become simple enough to be solved directly. The solutions to the sub-problems are then combined to give a solution to the original problem."
								link="/code-snippets/min-max-using-divide-and-conquer-code"
							/>
						</Row>
						<Row>
							<Product
								name="Trying to use an Insertion Sort On a Linked List and Failing Miserably | Code Tutorial"
								description="Using the Insertion Sort with a Linked List."
								link="/code-snippets/insertion-sort-code"
							/>
						</Row>
						<Row>
							<Product
								name="Snake And Ladder Problem | Code Tutorial"
								description="Given a snake and ladder board, find the minimum number of dice throws required to reach the destination or last cell from source or 1st cell."
								link="/code-snippets/snake-and-ladder-problem-code"
							/>
						</Row>
						<Row>
							<Product
								name="Kruskal's Algorithm | Code Tutorial | Part 1"
								description="Kruskal's algorithm is a minimum-spanning-tree algorithm which finds an edge of the least possible weight that connects any two trees in the forest. It is a greedy algorithm in graph theory as it finds a minimum spanning tree for a connected weighted graph adding increasing cost arcs at each step."
								link="/code-snippets/kruskals-algorithm-code"
							/>
						</Row>
						<Row>
							<Product
								name="Radix Sort | Code Tutorial | Part 1"
								description="In computer science, radix sort is a non-comparative integer sorting algorithm that sorts data with integer keys by grouping keys by the individual digits which share the same significant position and value."
								link="/code-snippets/radix-sort-code"
							/>
						</Row>
						<Row>
							<Product
								name="Reversing a Linked List | Code Tutorial"
								description="A code tutorial on how to reverse a linked list."
								link="/code-snippets/reversing-a-linked-list-code"
							/>
						</Row>
						<Row>
							<Product
								name="AVL Trees | Code Tutorial"
								description="AVL tree is a self-balancing Binary Search Tree (BST) where the difference between heights of left and right subtrees cannot be more than one for all nodes."
								link="/code-snippets/avl-tree-code"
							/>
						</Row>
						<Row>
							<Product
								name="Heap Sort | Code Tutorial | Part 1"
								description="In computer science, heapsort is a comparison-based sorting algorithm."
								link="/code-snippets/heap-sort-code"
							/>
						</Row>
						<Row>
							<Product
								name="Floyd-Warshall Algorithm | Code Tutorial"
								description="In computer science, the Floyd–Warshall algorithm is an algorithm for finding shortest paths in a weighted graph with positive or negative edge weights (but with no negative cycles). A single execution of the algorithm will find the lengths (summed weights) of shortest paths between all pairs of vertices."
								link="/code-snippets/floyd-warshall-code"
							/>
						</Row>
						<Row>
							<Product
								name="Huffman Algorithm | Code Tutorial"
								description="In computer science and information theory, a Huffman code is a particular type of optimal prefix code that is commonly used for lossless data compression."
								link="/code-snippets/huffman-algorithm-code"
							/>
						</Row>
						<Row>
							<Product
								name="Longest Palindrome Substring with Dynamic Programming | Code Tutorial"
								description="In computer science, a Linked list is a linear collection of data elements, whose order is not given by their physical placement in memory."
								link="/code-snippets/longest-palindrome-substring-dynamic-programming-code"
							/>
						</Row>
						<Row>
							<Product
								name="Linked List | Code Tutorial"
								description=""
								link="/code-snippets/linked-list-code"
							/>
						</Row>
						<Row>
							<Product
								name="Infix to Postfix | Binary Expression Tree | Code Tutorial"
								description=""
								link="/code-snippets/infix-to-prefix-code-binary-expression-tree-code"
							/>
						</Row>
						<Row>
							<Product
								name="Manacher's Algorithm | Code Tutorial and Explanation"
								description=""
								link="/code-snippets/manachers-algorithm-code"
							/>
						</Row>
						<Row>
							<Product
								name="Bucket Sort | Code Tutorial"
								description=""
								link="/code-snippets/bucket-sort-code"
							/>
						</Row>
						<Row>
							<Product
								name="Shell Sort | Code Tuturial"
								description=""
								link="/code-snippets/shell-sort-code"
							/>
						</Row>
						<Row>
							<Product
								name="Counting Sort | Code Tutorial"
								description=""
								link="/code-snippets/counting-sort-code"
							/>
						</Row>
						<Row>
							<Product
								name="Optimal Binary Search Tree | Code Tutorial"
								description=""
								link="/code-snippets/optimal-binary-search-tree-code"
							/>
						</Row>
						<Row>
							<Product
								name="Fibonacci Search (Ascending) | Code Tutorial | Search Algorithm"
								description=""
								link="/code-snippets/fibonacci-search-sort"
							/>
						</Row>
						<Row>
							<Product
								name="Mergesort | Code Tutorial | Part 1"
								description=""
								link="/code-snippets/merge-sort-code"
							/>
						</Row>
						<Row>
							<Product
								name="Decimal To Binary using Stack | Code Tutorial"
								description=""
								link="/code-snippets/decimal-to-binary-with-stack-code"
							/>
						</Row>
						<Row>
							<Product
								name="Booth's Algorithm | Code Simulation"
								description=""
								link="/code-snippets/booths-algorithm-code"
							/>
						</Row>
						<Row>
							<Product
								name="Jump Search in C++ | Code Tutorial"
								description=""
								link="/code-snippets/jump-search-code"
							/>
						</Row>
						<Row>
							<Product
								name="Exponential Search | Code Tutorial"
								description=""
								link="/code-snippets/exponential-search-code"
							/>
						</Row>
						<Row>
							<Product
								name="TimSort | Sorting Algorithm"
								description=""
								link="/code-snippets/timsort-algorthm-search-code"
							/>
						</Row>
						<Row>
							<Product
								name="Bitonic Merge Sort | Code Tutorial | Everything you need to know!"
								description=""
								link="/code-snippets/bitonic-merge-sort-code"
							/>
						</Row>
						<Row>
							<Product
								name="Odd Even Sort and Memory Allocation with Pointers in C"
								description=""
								link="/code-snippets/odd-even-sort-code"
							/>
						</Row>
						<Row>
							<Product
								name="Write a Binary Search Tree from Scratch | Code Tutorial | Part 1"
								description=""
								link="/code-snippets/binary-search-tree-code"
							/>
						</Row>
						<Row>
							<Product
								name="Linear Search | Code Tutorial"
								description=""
								link="/code-snippets/linear-search-code"
							/>
						</Row>
						<Row>
							<Product
								name="Longest Palindrome Sub String | Brute Force Method"
								description=""
								link="/code-snippets/longest-palindrome-substring-brute-force-method-code"
							/>
						</Row>
						<Row>
							<Product
								name="Rotate a Linked List | Code Tutorial"
								description=""
								link="/code-snippets/rotate-a-linked-list-code"
							/>
						</Row>
						<Row>
							<Product
								name="Interpolation Search | Code Tutorial"
								description=""
								link="/code-snippets/interpolation-search-code"
							/>
						</Row>
						<Row>
							<Product
								name="Bubblesort | Code Tutorial"
								description=""
								link="/code-snippets/bubblesort-code"
							/>
						</Row>
						<Row>
							<Product
								name="Quicksort | Code Tutorial | Part 1"
								description=""
								link="/code-snippets/quicksort-code"
							/>
						</Row>
						<Row>
							<Product
								name="Recursive Linear Search | Code Tutorial"
								description=""
								link="/code-snippets/recursive-linear-search-code"
							/>
						</Row>
						<Row>
							<Product
								name="Least Common Multiple | Code Tutorial | Euclidean Algorithm"
								description=""
								link="/code-snippets/least-common-multiple-code"
							/>
						</Row>
						<Row>
							<Product
								name="Integer Partitioning with Dynamic Programming | Code Tutorial"
								description=""
								link="/code-snippets/integer-partitioning-dynamic-programming-code"
							/>
						</Row>
						<Row>
							<Product
								name="Write a Stack from Scratch | Code Tutorial"
								description=""
								link="/code-snippets/stack-code"
							/>
						</Row>
						<Row>
							<Product
								name="Pigeonhole Sort | Code Tutorial | Sorting Algorithm"
								description=""
								link="/code-snippets/pigeonhole-sort-code"
							/>
						</Row>
						<Row>
							<Product
								name="Preorder and Inorder to Binary Tree | Code Tutorial"
								description=""
								link="/code-snippets/preorder-inorder-to-binary-tree"
							/>
						</Row>
						<Row>
							<Product
								name="Cocktail Sort | Sorting Algorithm"
								description=""
								link="/code-snippets/cocktail-sort-code"
							/>
						</Row>
						<Row>
							<Product
								name="Make A Simple JDBC Console | Part 1 | Code Tutorial"
								description=""
								link="/code-snippets/simple-jdbc-console-code"
							/>
						</Row>
						<Row>
							<Product
								name="Insertion Sort | Code Tutorial"
								description=""
								link="/code-snippets/insertion-sort-code"
							/>
						</Row>
						<Row>
							<Product
								name="Insertion Sort with Linked List | Code Tutorial"
								description=""
								link="/code-snippets/insertion-sort-linked-list-code"
							/>
						</Row>
						<Row>
							<Product
								name="Cycle Sort | Sorting Algorithm"
								description=""
								link="/code-snippets/cycle-sort-code"
							/>
						</Row>
						<Row>
							<Product
								name="Write a Queue from Scratch | Code Tutorial | Part 1"
								description=""
								link="/code-snippets/queue-code"
							/>
						</Row>
						<Row>
							<Product
								name="Combinations Of String | Code Tutorial"
								description=""
								link="/code-snippets/combinations-of-string-code"
							/>
						</Row>
						<Row>
							<Product
								name="Selection Sort | Code Tutorial"
								description=""
								link="/code-snippets/selection-sort-code"
							/>
						</Row>
						<Row>
							<Product
								name="Generate Power Set with Bitwise Operators | Code Tutorial"
								description=""
								link="/code-snippets/power-set-with-bitwise-operators-code"
							/>
						</Row>
						<Row>
							<Product
								name="Inorder and Postorder To Binary Tree | Code Tutorial"
								description=""
								link="/code-snippets/inorder-to-postorder-binary-tree-code"
							/>
						</Row>
						<Row>
							<Product
								name="Fibonacci Numbers | Code Tutorial"
								description=""
								link="/code-snippets/fibonacci-numbers-code"
							/>
						</Row>
						<Row>
							<Product
								name="Comb Sort | Code Tutorial"
								description=""
								link="/code-snippets/comb-sort-code"
							/>
						</Row>
						<Row>
							<Product
								name="Cartesian Product of Two Sets | Code Tutorial"
								description=""
								link="/code-snippets/cartesian-product-two-sets-code"
							/>
						</Row>
						<Row>
							<Product
								name="Strand Sort | Code Tutorial"
								description=""
								link="/code-snippets/strand-sort-code"
							/>
						</Row>
						<Row>
							<Product
								name="Greatest Common Divisor | Code Tutorial | Euclidean Algorithm"
								description=""
								link="/code-snippets/greatest-common-divisor-code"
							/>
						</Row>
						<Row>
							<Product
								name="Find Largest Cluster of Ones from 2D Array | Code Tutorial"
								description=""
								link="/code-snippets/cluster-of-ones-2d-array-code"
							/>
						</Row>
						<Row>
							<Product
								name="Factorization of Numbers | Code Tutorial"
								description=""
								link="/code-snippets/factorization-of-numbers-code"
							/>
						</Row>
						<Row>
							<Product
								name="Check if you get the Power from the Base | Code Tutorial"
								description=""
								link="/code-snippets/power-from-base-code"
							/>
						</Row>
					</Col>
				</Container>

				{/* <Container style={{ padding: 20 }}>
					<Row>
						{(this.state.data.length > 0) ?
						this.state.data.map((currentValue, index) => (
							<Col xs={12} md={4} lg={3}>
								<Row>
									<Product
										name={currentValue.fields.header}
										decription={currentValue.fields.description}
										link={`/code-snippets/${currentValue.fields.slug}`}
									/>
								</Row>
							</Col>
						))
						:
						null
						}
					</Row>
				</Container> */}

				<DonationFooter />
			</Layout>
		);
	}
}

export default CodeSnippets;
